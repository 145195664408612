import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import createApiSlice from "dmc-rtkquery";
import BaseApi from "src/store/apis/BaseApi";
import appConfigSlice from "src/store/slices/appConfig";
import appLocalesSlice from "src/store/slices/appLocales";
import config from "../app.config.json";
const BASE_URL = config.base_api_url;
const GATEWAY_USERNAME = process.env.REACT_APP_GATEWAY_USERNAME;
const GATEWAY_PASSWORD = process.env.REACT_APP_GATEWAY_PASSWORD;
const GATEWAY_GRANT_TYPE = process.env.REACT_APP_GATEWAY_GRANT_TYPE;
const COUPONZ_CLIENT_ID = process.env.REACT_APP_COUPONZ_CLIENT_ID;
const LOYALTY_CLIENT_ID = process.env.REACT_APP_LOYALTY_CLIENT_ID;

const api = createApiSlice(BASE_URL!, {
  gatewayUsername: GATEWAY_USERNAME!,
  gatewayPassword: GATEWAY_PASSWORD!,
  gatewayToken: `Bearer ${localStorage.getItem("GATEWAY_TOKEN")}`,
  gatewayGrantType: GATEWAY_GRANT_TYPE!,
  couponzClientId: COUPONZ_CLIENT_ID!,
  loyaltyClientId: LOYALTY_CLIENT_ID!,
  portalToken: `Bearer ${localStorage.getItem("PORTAL_TOKEN")}`,
});

export const store = configureStore({
  reducer: {
    [BaseApi.reducerPath]: BaseApi.reducer,
    [api.reducerPath]: api.reducer,
    appConfig: appConfigSlice,
    appLocales: appLocalesSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(BaseApi.middleware, api.middleware);
  },
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const {
  useGetCategoriesListForOffersQuery,
  useGetDealsListPagedQuery,
  useGetMerchantListQuery,
  useLazyPortalLoginQuery,
  useLazyRegisterQuery,
  useLazySendOtpQuery,
  useLazyVerifyVoucherOtpQuery,
  useLazyForgetPasswordQuery,
  useGetOfferSectionQuery,
  useGetWalletDataQuery,
  useLazyGetGetawayTokenQuery,
  useLazyGetAllTransactionsQuery,
  useLazyGetCustomerHistoryQuery,
  useRedeemQuery,
  useLazyGetOnlineMerchantsQuery,
  useLazySubscribeToOfferQuery,
  useLazyRedeemQuery,
} = api;
