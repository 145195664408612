import {
  ConfirmGetDeal,
  DoneRedeemPoints,
  RedemptionFlow,
  VoucherDetails,
} from "@dsquares/react-component-library";
import { useState } from "react";
import { Merchant } from "src/components/RedemptionModal/MerchantFlow";
import { useAppTranslation } from "src/hooks/useAppTranslation";

type Props = {
  isOpen: boolean;
  merchant: Merchant;
  onModalClose: () => void;
};

export default function DealsFlow({ isOpen, merchant, onModalClose }: Props) {
  const [currentStep, setCurrentStep] = useState(0);
  const { t, i18n } = useAppTranslation("RedemptionFlow");

  return (
    <>
      <RedemptionFlow
        open={isOpen}
        onCancel={() => {
          onModalClose();
          setCurrentStep(0);
        }}
        onChangeStep={() => {}}
        currentStep={currentStep}
        title={`${t("RedeemPoints")}`}
        stepper={{
          stepsTitles: [
            {
              title: t("Redeem"),
            },
            {
              title: t("Voucher"),
            },
          ],
        }}
      >
        {({ step }) => (
          <>
            <ConfirmGetDeal
              step={0}
              currentStep={step}
              dealType="offer"
              offerItem={{
                merchantName: merchant.name,
                offerImage: merchant.image,
              }}
              confimationMessage={t("ConfirmRedeem")}
              nextProps={{
                title: t("Next"),
                onClick: () => setCurrentStep((prev) => prev + 1),
              }}
              prevProps={{
                title: t("Cancel"),
                onClick: () => {
                  onModalClose();
                },
              }}
            />
            <VoucherDetails
              step={1}
              currentStep={step}
              message={`${t("ScanMsgPt1")} ${merchant.name} ${t("ScanMsgPt2")}`}
              isLoading={false}
              voucher={{ title: t("VoucherCode"), code: "12345678" }}
              expirationProps={{
                title: t("ExpirationDate"),
                date: "2024/07/11",
              }}
              confirmProps={{
                title: t("Finish"),
                onClick: () => onModalClose(),
              }}
            />
          </>
        )}
      </RedemptionFlow>
    </>
  );
}
