import { CaretDownOutlined } from "@ant-design/icons";
import { LoginPage as DemoLoginPage } from "@dsquares/react-component-library";
import { Link } from "react-router-dom";
import bgAnimation from "src/assets/video/auth-bg-animation.mp4";
import { useToast } from "src/context/ToastContext";
import { useLazyPortalLoginQuery } from "src/store";
import { LanguageService } from "src/services/Language";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";

type LoginFormValues = {
  mobile: string;
  password: string;
};

const Login = () => {
  const { i18n, t } = useAppTranslation();
  const notification = useToast();
  const appConfig = useSelector(selectAppConfig);

  const lightLogo = appConfig.lightLogo;
  const appVariant = appConfig.variant;

  const isArabic = i18n.language === "ar";

  const [login, { isLoading }] = useLazyPortalLoginQuery();

  const handleLogin = async (e: LoginFormValues) => {
    try {
      const valideMobileNumber = e.mobile.replace("+2", "");
      const res = await login({
        username: valideMobileNumber,
        password: e.password,
      });

      if (res?.isSuccess) {
        localStorage.setItem("PORTAL_TOKEN", res.data?.Token);
        localStorage.setItem("USER_NAME", valideMobileNumber);
        localStorage.setItem("Accept_Language", isArabic ? "ar" : "en");
      } else if (res?.isError) {
        if ("data" in res?.error) {
          notification.open({
            type: "error",
            content: (res.error.data as any).errorMessage,
            duration: 3,
          });
        }
      }
      if (res.data?.Token && localStorage.getItem("PORTAL_TOKEN")) {
        // TODO: Danger
        window.location.href = "/";
      }
    } catch (error: any) {
      notification.open({
        type: "error",
        content: error.message,
        duration: 3,
      });
    }
  };

  return (
    <DemoLoginPage
      className="w-full mx-auto max-w-6xl"
      background={
        <>
          <video
            autoPlay
            className="hidden md:block absolute inset-0 w-full h-full object-cover -z-10 "
            loop
            muted
          >
            <source src={bgAnimation} type="video/mp4" />
          </video>
          <div className="absolute hidden md:block inset-0 bg-primaryDark opacity-80 -z-10" />
        </>
      }
      footer={{
        links: [
          {
            children: t("FAQ"),
            href: "/",
          },
          {
            children: t("TermsAndConditions"),
            href: "/",
          },
          {
            children: t("PrivacyPolicy"),
            href: "/",
          },
        ],
        logo: <p className="text-white">{t("Copyright")} Dsquares 2024</p>,
      }}
      formSettings={{
        footer: (
          <div className="flex flex-col gap-2">
            <Link
              to="/forget-password"
              className="text-primaryDark font-medium"
            >
              {t("Login.forgotPassword")}
            </Link>
            <p className="font-medium text-gray-400">
              {t("Login.dontHaveAccount")}{" "}
              <Link className="text-primaryDark" to="/signup">
                {t("Login.registerNow")}
              </Link>
            </p>
          </div>
        ),
        onSubmit: (e: LoginFormValues) => handleLogin(e),
        title: (
          <h2 className="text-20 text-28 font-medium">{t("Login.title")}</h2>
        ),
        mobileInputProps: {
          label: t("Login.mobileNumberLabel"),
          placeholder: t("Login.mobileNumberPlaceholder"),
          rules: [{ required: true, message: t("ThisFieldIsRequired") }],
        },
        passwordInputProps: {
          label: t("Login.passwordLabel"),
          placeholder: t("Login.passwordPlaceholder"),
          rules: [{ required: true, message: t("ThisFieldIsRequired") }],
        },
        submitProps: {
          loading: isLoading,
          label: t("Login.login"),
        },
      }}
      header={{
        logo: (
          <a className="flex justify-center" href="/">
            <img
              src={lightLogo!}
              alt="Company logo"
              className="object-contain w-40 h-10 ltr:object-left rtl:object-right"
            />
          </a>
        ),
        languageSwitcherProps: {
          value: i18n.language,
          items: [
            { label: "English", value: "en" },
            { label: "العربية", value: "ar" },
          ],
          handleDirection: (value: "ar" | "en") =>
            LanguageService.changeLanguage(value as "ar" | "en"),
          suffixLanguageIcon: <CaretDownOutlined className={"text-white"} />,
          textColor: "white",
          bordered: false,
        },
      }}
    >
      {/* This fragment is because children marked as required in this component */}
      {/* TODO: Fix in design system */}
      <></>
    </DemoLoginPage>
  );
};

export default Login;
