import { Link, useNavigate } from "react-router-dom";
import { useGetOfferSectionQuery, useGetWalletDataQuery } from "src/store";
import dayjs from "dayjs";
import GoldIcon from "../../assets/goldLogo.png";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import { checkAuth } from "src/utility/utility";
import useSubscribe from "src/hooks/useSubscribe";
import { DealsCard, DemoHomepage } from "@dsquares/react-component-library";
import { AppVariant } from "@dsquares/react-component-library/constants";
import MerchantFlow from "./../../components/RedemptionModal/MerchantFlow";
import DealsFlow from "src/components/RedemptionModal/DealsFlow";
import { Merchant } from "./../../components/RedemptionModal/MerchantFlow";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import { useLazyAppConfigQuery } from "src/store/apis/BaseApi";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";

type BalanceCardPropsType = {
  variant?: AppVariant;
  title?: string;
  tier?: string;
  content?: ReactNode;
  tierIcon?: ReactNode;
  points?: string | number;
  pointsSuffix?: ReactNode;
  isLoading?: boolean;
  historyText?: string;
  historyTo?: string;
  historyLink?: ReactElement;
  redeemText?: ReactNode;
  onRedeem?: () => void;
};

const HomePage = () => {
  // return null;
  const [isMerchantFlowModalOpen, setIsMerchantFLowModalOpen] = useState(false);
  const [isDealsFlowModalOpen, setIsDealsFlowModalOpen] = useState(false);
  const [getAppConfig] = useLazyAppConfigQuery();
  const navigate = useNavigate();

  const { i18n, t } = useAppTranslation("Homepage");

  const appConfig = useSelector(selectAppConfig);
  const appVariant = appConfig.variant;

  const [merchant, setMerchant] = useState<Merchant>();

  const [heroImages, setHeroImages] = useState<ReactElement[]>([]);
  const [offerBanner, setOfferBanner] = useState<string>("");

  const setupAppConfig = async () => {
    const res = await getAppConfig(undefined).unwrap();

    setHeroImages([
      <img
        className="w-full h-80 rounded-2xl"
        key="1"
        src={res?.heroImage1}
        alt="First hero"
      />,
      <img
        className="w-full h-80 rounded-2xl"
        key="2"
        src={res?.heroImage2}
        alt="Second hero"
      />,
      <img
        className="w-full h-80 rounded-2xl"
        key="3"
        src={res?.heroImage3}
        alt="Third hero"
      />,
    ]);
    setOfferBanner(res.offerImage);
  };

  useEffect(() => {
    setupAppConfig();
  }, []);

  const { data: walletData, isLoading: walletDataIsLoading } =
    useGetWalletDataQuery(undefined);

  // TODO: Add loading to balance card in ds
  const balanceCard: BalanceCardPropsType = {
    isLoading: walletDataIsLoading,
    title: t("Balance.Title"),
    redeemText: t("Balance.redeemText"),
    historyLink: <Link to="/" />,
    historyText: t("Balance.historyText"),
    tier: walletData?.data.segment.color,
    tierIcon: <img className="size-16" src={GoldIcon} alt="Gold tier icon" />,
    content: `${walletData?.data.totalPoints} ${t(
      "Balance.expiryText"
    )} ${dayjs(walletData?.data.expiryDate).format("DD/MM/YYYY")}`,
    points: walletData?.data.totalPoints,
    onRedeem: () => {
      setIsMerchantFLowModalOpen(true);
    },
  };

  const { data, isLoading, error } = useGetOfferSectionQuery({
    lang: i18n.language,
  });

  const { subscribeQuery, subscriptionResponse } = useSubscribe({
    offerNumber: "657",
  });

  const transformSections = data?.map((section) => ({
    itemTemplate: (item: any, id: number) => (
      <DealsCard
        key={id}
        variant={item.variant}
        image={item.offerImage}
        title={item.title}
        onCardClick={() => {}}
        description={item.description}
        isHotOffer={item.isHotOffer}
        locked={item.locked}
        pointsMessage={item.pointsMessage}
        mainAction={{
          onClick: async () => {
            setIsDealsFlowModalOpen(true);
            // await subscribeQuery();
            setMerchant({
              id: id,
              name: item?.title,
              image: <img src={item.offerImage} alt={item.title} />,
            });
          },
          label: t("Claim"),
        }}
      />
    ),
    onSeeAll: () => navigate("/offers"),
    seeAllText: t("SeeAll"),
    title: section.SecionName,
    items: section.Offers.map((offer) => ({
      offerImage: offer.OfferImage,
      description: offer.OfferDesc,
      isHotOffer: offer.OfferTags.length > 0,
      locked: {
        isLocked: offer.Locked,
        lockMessage: t("Locked"),
      },
      pointsMessage: offer.Points?.toString(),
      title: offer.OfferTitle,
    })),
  }));

  checkAuth((error as any)?.originalStatus, () => navigate("/login"));

  return (
    <>
      <MerchantFlow
        isOpen={isMerchantFlowModalOpen}
        totalPoints={walletData?.data.totalPoints || 0}
        pointsExpirationDate={dayjs(walletData?.data.expiryDate).format(
          "DD/MM/YYYY"
        )}
        onModalClose={() => {
          setIsMerchantFLowModalOpen(false);
        }}
      />
      <DealsFlow
        isOpen={isDealsFlowModalOpen}
        onModalClose={() => {
          setIsDealsFlowModalOpen(false);
        }}
        merchant={merchant || ({} as any)}
      />
      <DemoHomepage
        variant={appVariant}
        renderHeroImages={(image) => <img src={image.src} alt={image.alt} />}
        direction={i18n.dir()}
        language={i18n.language}
        handleDirection={() => console.log("Hello")}
        ad={
          <Link to="/">
            <img
              alt="Ad"
              className="h-64 object-fill rounded-2xl"
              src={offerBanner}
            />
          </Link>
        }
        balanceProps={balanceCard}
        heroImages={heroImages}
        sliders={transformSections || []}
      />
    </>
  );
};

export default HomePage;
