import { CaretDownOutlined } from "@ant-design/icons";
import { ResetPassword as DemoResetPassword } from "@dsquares/react-component-library";
import { LanguageService } from "src/services/Language";
import bgAnimation from "src/assets/video/auth-bg-animation.mp4";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyForgetPasswordQuery } from "src/store";
import { useToast } from "src/context/ToastContext";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";

type ResetPasswordFormValues = {
  password: string;
  confirm: string;
};

const ResetPassword = () => {
  const { t } = useAppTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const notification = useToast();

  const { mobile, otp } = location.state || {};

  const [reset] = useLazyForgetPasswordQuery();
  const appConfig = useSelector(selectAppConfig);

  const lightLogo = appConfig.lightLogo;
  const appVariant = appConfig.variant;

  const handleResetPassword = (values: ResetPasswordFormValues) => {
    resetPassword({
      mobile: mobile,
      otp: otp,
      password: values.password,
      confirm: values.confirm,
    });
  };

  const resetPassword = async ({
    mobile,
    otp,
    password,
    confirm,
  }: {
    mobile: string;
    otp: string;
    password: string;
    confirm: string;
  }) => {
    try {
      const res = await reset({
        username: mobile,
        otp: otp,
        newPassword: password,
        confirmNewPassword: confirm,
      });

      if (res?.isSuccess) {
        notification.open({
          type: "success",
          content: t("ResetPassword.successMessage"),
          duration: 3,
        });
        navigate("/login");
      } else if (res?.isError) {
        if ("data" in res?.error) {
          notification.open({
            type: "error",
            content: (res.error.data as any).errorMessage,
            duration: 3,
          });
        }
      }
    } catch (error: any) {
      notification.open({
        type: "error",
        content: error.message,
        duration: 3,
      });
    }
  };

  return (
    <DemoResetPassword
      variant={appVariant}
      background={
        <>
          <video
            autoPlay
            muted
            loop
            className="hidden md:block absolute inset-0 w-full h-full object-cover -z-10 "
          >
            <source src={bgAnimation} type="video/mp4" />
          </video>
          <div className="absolute hidden md:block inset-0 bg-primaryDark opacity-80 -z-10"></div>
        </>
      }
      header={{
        logo: (
          <a className="flex justify-center" href="https://dsquares.com">
            <img
              src={lightLogo}
              alt="Company logo"
              className="hidden md:block w-72"
            />
          </a>
        ),
      }}
      footer={{
        links: [
          {
            children: t("FAQ"),
            href: "/",
          },
          {
            children: t("TermsAndConditions"),
            href: "/",
          },
          {
            children: t("PrivacyPolicy"),
            href: "/",
          },
        ],
        languageSwitcherProps: {
          items: [
            { label: "English", value: "en" },
            { label: "العربية", value: "ar" },
          ],
          handleDirection: (value: "ar" | "en") =>
            LanguageService.changeLanguage(value as "ar" | "en"),
          suffixLanguageIcon: <CaretDownOutlined className={"text-white"} />,
          textColor: "white",
          bordered: false,
        },
        logo: (
          <p className="text-white">
            {t("Copyright")} {new Date().getFullYear()}
          </p>
        ),
      }}
      formSettings={{
        title: t("ResetPassword.title"),
        onSubmit: (values: ResetPasswordFormValues) =>
          handleResetPassword(values),
        passwordInputProps: {
          placeholder: t("ResetPassword.newPasswordPlaceholder"),
          label: t("ResetPassword.newPasswordLabel"),
          rules: [
            {
              required: true,
              message: "Please input your password!",
            },
          ],
        },
        confirmPasswordInputProps: {
          placeholder: t("ResetPassword.confirmNewPasswordPlaceholder"),
          label: t("ResetPassword.confirmNewPasswordLabel"),
          rules: [
            {
              required: true,
              message: "Please confirm your password!",
            },
          ],
        },
        submitProps: {
          label: t("ResetPassword.save"),
          htmlType: "submit",
        },
      }}
    >
      {/* This fragment is because children marked as required in this component */}
      {/* TODO: Fix in design system */}
      <></>
    </DemoResetPassword>
  );
};
export default ResetPassword;
