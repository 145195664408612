import { useEffect, useState } from "react";
import { Spin } from "antd";
import {
  useLazyAppConfigQuery,
  useLazyAppLocalesQuery,
} from "src/store/apis/BaseApi";
import { useDispatch } from "react-redux";
import { reset, setAppConfig } from "src/store/slices/appConfig";
import { resetLocales, setAppLocales } from "src/store/slices/appLocales";
import { hexToRgb, normalizeBy } from "src/utility/utility";
import { useDebounce } from "src/hooks/useDebounce";

export function withAppConfig<P extends object>(
  Component: React.ComponentType<P>
) {
  return function (props: P) {
    const [ready, setReady] = useState(false);
    const debouncedReady = useDebounce(ready, { delay: 3000 });
    const dispatch = useDispatch();

    const [getAppConfig, { isLoading: isLoadingConfig }] =
      useLazyAppConfigQuery();
    const [getAppLocales, { isLoading: isLoadingLocales }] =
      useLazyAppLocalesQuery();

    const setupAppConfig = () => {
      getAppConfig(undefined)
        .unwrap()
        .then((res) => {
          const colors = {
            primaryColor: res.primary_color,
            secondaryColor: res.secondary_color,
            textColor: res.text_color,
            dangerColor: res.danger_color,
            successColor: res.success_color,
            whiteColor: res.white_color,
            blackColor: res.black_color,
          };

          const baseUrl = res.base_api_url;
          const variant = res.variant;
          const darkLogo = res.darkLogo;
          const lightLogo = res.lightLogo;
          const favicon = res.favicon;

          dispatch(
            setAppConfig({
              colors,
              baseUrl,
              variant,
              darkLogo,
              lightLogo,
              favicon,
            })
          );
        })
        .catch(() => {
          dispatch(reset());
        })
        .finally(() => {
          setReady(true);
        });
    };
    const setupAppLocales = () => {
      getAppLocales(undefined)
        .unwrap()
        .then((res) => {
          const locales: Record<string, Record<string, string>> = {
            ar: {},
            en: {},
          };
          res?.forEach((item): void => {
            locales[item.language] = normalizeBy<any, string>(
              item.keys,
              "key",
              (key) => key.value
            );
          });
          dispatch(setAppLocales({ locales }));
        })
        .catch(() => {
          dispatch(resetLocales());
        })
        .finally(() => {
          setReady(true);
        });
    };

    useEffect(() => {
      setupAppConfig();
      setupAppLocales();
    }, []);

    const isLoading = isLoadingConfig || isLoadingLocales;
    if (isLoading || !debouncedReady) {
      return (
        <Spin
          size="large"
          spinning={isLoading || !debouncedReady}
          indicator={
            <span className="flex h-20 w-20 items-center justify-center">
              <DsquaresLogo />
            </span>
          }
        >
          <div style={{ height: "100vh", width: "100vw" }} />
        </Spin>
      );
    }

    return <Component {...props} />;
  };
}

const DsquaresLogo = () => {
  const logoStyle = {
    animation: "bounce 0.9s infinite",
  };

  const bounceAnimation = `
    @keyframes bounce {
      0%, 100% {
        transform: translateY(22%);
        animation-timing-function: cubic-bezier(0.0, 0, .2, 1);
      }
      50% {
        transform: translateY(0%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      }
    }
  `;

  return (
    <svg
      width="500"
      height="530"
      viewBox="0 0 500 530"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{bounceAnimation}</style>
      <path
        d="M295.726 278.163V164.568H91V482.889H409.321V278.163H295.726ZM295.676 373.712H200.177V278.213H295.676V373.712Z"
        fill="#0071CE"
      />
      <path
        style={logoStyle}
        className="logo"
        d="M407.509 47H295.725V158.784H407.509V47Z"
        fill="#0071CE"
      />
    </svg>
  );
};
