export const PARTNERS_ROUTE = "/partners";

export const DealRoutes = [
  {
    id: undefined,
    path: "/offers",
  },
  {
    id: 11,
    path: "/vouchers",
  },
  // {
  //   id: 11,
  //   path: "/giftcards",
  // },
  {
    id: 11,
    path: "/programs",
  },
  {
    id: undefined,
    path: PARTNERS_ROUTE,
  },
];
