export const WarningIcon = () => (
  <span className="flex items-center justify-center w-7 h-7">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M20 36.6667C29.2048 36.6667 36.6667 29.2048 36.6667 20C36.6667 10.7953 29.2048 3.33337 20 3.33337C10.7953 3.33337 3.33337 10.7953 3.33337 20C3.33337 29.2048 10.7953 36.6667 20 36.6667Z"
        fill="#D14343"
      />
      <path
        d="M24.6867 27.025L12.975 15.3133C12.335 14.6733 12.335 13.6333 12.975 12.9933L12.9933 12.975C13.6333 12.335 14.6733 12.335 15.3133 12.975L27.025 24.6867C27.665 25.3267 27.665 26.3667 27.025 27.0067L27.0067 27.025C26.3667 27.665 25.3267 27.665 24.6867 27.025Z"
        fill="white"
      />
      <path
        d="M12.975 24.6867L24.6867 12.975C25.3267 12.335 26.3667 12.335 27.0067 12.975L27.025 12.9933C27.665 13.6333 27.665 14.6733 27.025 15.3133L15.3133 27.025C14.6733 27.665 13.6333 27.665 12.9933 27.025L12.975 27.0067C12.335 26.3667 12.335 25.3267 12.975 24.6867Z"
        fill="white"
      />
    </svg>
  </span>
);
