import { PartnersList } from "@dsquares/react-component-library";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import useDeals from "src/hooks/useDeals";
import { PARTNERS_ROUTE } from "src/models/RoutesModel";
import { checkAuth } from "src/utility/utility";

type OffersPageProps = {
  pathname: string;
  sectionId: number | undefined;
};

const DealsPage = ({ pathname, sectionId }: OffersPageProps) => {
  const navigate = useNavigate();

  const { i18n, t } = useAppTranslation("DealsPage");

  const [pageNumber, setPageNumber] = useState(1);

  const {
    categories,
    transformedData,
    totalItemsCount,
    isLoading,
    isError,
    error,
    onCategoryClicked,
    categoryId,
    merchantsData,
    offerData,
  } = useDeals(pageNumber, pathname, sectionId);

  const onCardClick = (id: number | string | undefined) => {
    if (id) {
      const cardDetails =
        pathname.toLowerCase() === PARTNERS_ROUTE
          ? merchantsData?.Data.find((merchant) => merchant.MerchantId === +id)
          : offerData?.Items.find((offer) => offer.Id === +id);
      navigate(String(id), {
        state: {
          data: cardDetails,
        },
      });
    }
  };

  checkAuth((error as any)?.originalStatus, () => {
    navigate("/login");
  });

  // return null;
  return (
    <PartnersList
      hasSearch={false}
      hasFilters={false}
      partners={{
        currentPage: pageNumber,
        total: totalItemsCount || 0,
        count: transformedData.length,
        list: transformedData.map((dataItem) => ({
          ...dataItem,
          onCardClick: onCardClick,
        })) as any,
        pageSize: 8,
        isLoading: isLoading,
        isError: isError,
        handlePagination: (page) => {
          setPageNumber(page);
        },
      }}
      categories={{
        title: t("categoriesTitle"),
        list: [
          {
            title: t("all"),
            image: <img src="" />,
            categoryId: undefined,
          },
          ...categories,
        ],
        selectedCategoryID: categoryId,
        onCategoryClicked,
      }}
      filter={{
        menuItems: [
          // ... (your existing filter menu items)
        ],
        showBadge: false,
      }}
    />
  );
};

export default DealsPage;
