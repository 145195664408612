import { message } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { createContext, useContext } from "react";

export const ToastContext = createContext<MessageInstance | null>(null);

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <ToastContext.Provider value={messageApi}>
      {contextHolder}
      {children}
    </ToastContext.Provider>
  );
}

export function useToast() {
  const messageApi = useContext(ToastContext);
  if (!messageApi) {
    throw new Error("useToast must be used within ToastProvider");
  }
  return messageApi;
}
