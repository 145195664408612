import {
  RedemptionFlow,
  ChooseMerchant,
  RedeemPoints,
  VoucherDetails,
} from "@dsquares/react-component-library";
import { ReactElement, useEffect, useState } from "react";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import useOnlineMerchants from "src/hooks/useOnlineMerchants";

type Props = {
  isOpen: boolean;
  totalPoints: number;
  pointsExpirationDate: string;
  onModalClose: () => void;
};

export type Merchant = {
  id: number;
  name?: string;
  image?: ReactElement;
};

export default function MerchantFlow({
  isOpen,
  totalPoints,
  pointsExpirationDate,
  onModalClose,
}: Props) {
  const { t, i18n } = useAppTranslation("RedemptionFlow");
  const [currentStep, setCurrentStep] = useState(0);
  const [calculatedPoints, setCalculatedPoints] = useState(0);

  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();

  const { onlineMerchants, onlineMerchantsIsLoading, fetchOnlineMerchants } =
    useOnlineMerchants();

  useEffect(() => {
    (async () => {
      if (isOpen) {
        await fetchOnlineMerchants();
      }
    })();
  }, [isOpen]);

  return (
    <>
      <RedemptionFlow
        open={isOpen}
        onCancel={() => {
          onModalClose();
          setCurrentStep(0);
          setCalculatedPoints(0);
          setSelectedMerchant(undefined);
        }}
        currentStep={currentStep}
        onChangeStep={() => {}}
        title={`${t("RedeemPoints")}`}
        stepper={{
          stepsTitles: [
            {
              title: t("Choose"),
            },
            {
              title: t("Redeem"),
            },
            {
              title: t("Voucher"),
            },
          ],
        }}
      >
        {({ step }) => (
          <>
            <ChooseMerchant
              isLoading={onlineMerchantsIsLoading}
              search={{
                placeholder: t("SearchForMerchant"),
              }}
              nextProps={{
                title: t("Next"),
                onNextClick: () => setCurrentStep((prev) => prev + 1),
              }}
              selectedMerchant={selectedMerchant}
              onClickMerchant={(merchant) => setSelectedMerchant(merchant)}
              merchantList={onlineMerchants || []}
              title={`${step + 1}. ${t("ChooseMerchant")}`}
              description={`${t("ChooseYourMerchant")}`}
              step={0}
              currentStep={step}
            />
            <RedeemPoints
              isLoading={false}
              title={`${step + 1}. ${t("RedeemPoints")}`}
              description={`${t("RedeemYourPoints")}`}
              step={1}
              currentStep={step}
              merchant={selectedMerchant}
              nextProps={{
                title: t("Next"),
                onClick: () => setCurrentStep((prev) => prev + 1),
              }}
              prevProps={{
                title: t("Back"),
                onClick: () => setCurrentStep((prev) => prev - 1),
              }}
              changeProps={{
                label: t("Change"),
                onClick: () => setCurrentStep((prev) => prev - 1),
              }}
              points={{
                title: t("YourPoints"),
                description: `${t("ExpiresAt")} ${pointsExpirationDate}`,
                points: `${totalPoints} ${t("Points")}`,
              }}
              calculatePointsProps={{
                title: t("CalculatePoints"),
                onCalculate: (value) => {
                  if (value) setCalculatedPoints(+value);
                },
                result: calculatedPoints,
                placeholder: `1000 ${t("Points")}`,
                currency: t("Currency"),
              }}
            />
            <VoucherDetails
              step={2}
              isLoading={false}
              currentStep={step}
              confirmProps={{
                title: <p>{t("Close")}</p>,
                onClick: onModalClose,
              }}
              title={`${t("redemptionSuccessful")}`}
              description={`${t("YouHaveRedeemed")} ${calculatedPoints} ${t(
                "Points"
              )} ${t("For")} ${calculatedPoints} ${t("Currency")}`}
              voucher={{
                title: t("YourVoucher"),
                code: "328479823",
                onCopy: () => {},
              }}
            />
          </>
        )}
      </RedemptionFlow>
    </>
  );
}
