import {
  RedeemRequest,
  RedeemResponse,
  SubscribeToOfferResponse,
} from "dmc-rtkquery/dist/models";
import { useState } from "react";
import { useToast } from "src/context/ToastContext";
import { useLazySubscribeToOfferQuery } from "src/store";

const useSubscribe = ({ offerNumber }: { offerNumber: string }) => {
  const [subscriptionResponse, setSubscriptionResponse] =
    useState<SubscribeToOfferResponse>();
  const [subscriptionIsLoading, setsubscriptionIsLoading] =
    useState<boolean>(false);
  const [subscriptionError, setsubscriptionError] = useState();

  const notification = useToast();

  const [subscribeToOffer] = useLazySubscribeToOfferQuery();

  const subscribeQuery = async () => {
    setsubscriptionIsLoading(true);
    try {
      setsubscriptionIsLoading(true);
      const { data, error, isSuccess } = await subscribeToOffer({
        offerNumber,
      });

      if (isSuccess) {
        setSubscriptionResponse(data);
        setsubscriptionIsLoading(false);
      }

      if (error) {
        notification.open({
          content: (error as any)?.data?.errorMessage,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching online merchants:", error);
    }
  };

  return {
    subscribeQuery,
    subscriptionError,
    subscriptionResponse,
    subscriptionIsLoading,
  };
};

export default useSubscribe;
