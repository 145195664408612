import {
  ClockCircleOutlined,
  FacebookOutlined,
  GlobalOutlined,
  InstagramOutlined,
  TwitterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, NavLink, Outlet, To, useNavigate } from "react-router-dom";
import { DealRoutes } from "src/models/RoutesModel";
import { LanguageService } from "src/services/Language";
import DsquaresLogo from "../../src/assets/dsquares-logo.png";

import {
  SharedHeader,
  Footer,
  useTheme,
} from "@dsquares/react-component-library";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import { useGetWalletDataQuery } from "src/store";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";
import classNames from "classnames";

export default function Layout() {
  const navigate = useNavigate();
  const appConfig = useSelector(selectAppConfig);

  const { i18n, t } = useAppTranslation();

  const userName: string | null = localStorage.getItem("USER_NAME");

  const darkLogo = appConfig.darkLogo;

  const handleLogout = () => {
    localStorage.removeItem("PORTAL_TOKEN");
    localStorage.removeItem("USER_NAME");
    localStorage.removeItem("Accept_Language");
    navigate("/login");
  };

  const footerLinks = [
    { href: "/faq", children: t("Layout.faq") },
    { href: "/terms", children: t("Layout.terms") },
    { href: "/privacy", children: t("Layout.privacy") },
  ];

  const { theme } = useTheme();
  const appVariant = theme.variant;

  const headerLang =
    appVariant === "demoPortal"
      ? {
          langText: i18n.language,
        }
      : {
          langText: i18n.language === "en" ? "عربي" : "en",
          setLanguage: () => {
            LanguageService.switchLanguage();
          },
        };

  const { data: walletData } = useGetWalletDataQuery(undefined);

  return (
    <>
      <header className="[&_.ant-select]:h-10 [&_.ant-select+button]:h-10 [&_nav]:px-0">
        <SharedHeader
          // @ts-ignore
          language={headerLang}
          variant={appVariant as any}
          placement="left"
          phoneNumber={userName as string | undefined}
          secondSuffixIcon={<UserOutlined />}
          handleDirection={(value) =>
            LanguageService.changeLanguage(value as "ar" | "en")
          }
          LanguageSwitcherProp={{
            items: [
              {
                key: "1",
                label: "English",
                value: "en",
              },
              {
                key: "2",
                label: "العربية",
                value: "ar",
              },
            ],
            onMenuClick: undefined,
            suffixLanguageIcon: <i className="text-danger-active" />,
          }}
          direction={i18n.dir()}
          hasAccountMenu
          hasLanguageSwitcher
          itemWithIcon={{
            icon: <div />,
            label: "Item With Icon",
            to: "/item",
          }}
          logo={
            <Link to="/" className="overflow-hidden">
              <img
                className="w-48 h-10 object-contain ltr:object-left rtl:object-right"
                src={darkLogo}
                alt="Company logo"
              />
            </Link>
          }
          menuProps={{
            items: [
              {
                itemIcon: <ClockCircleOutlined />,
                key: "2",
                label: (
                  <Link className="text-gray-700" to="/history">
                    {t("Layout.history")}
                  </Link>
                ),
              },
              {
                itemIcon: null,
                key: "button",
                label: t("Layout.logout"),
                onClick: () => handleLogout(),
              },
            ],
            onClick: function Ga() {},
          }}
          navLinks={[
            ...DealRoutes.map((route) => ({
              children: t(`Layout.${route.path.slice(1)}`),
              to: route.path,
            })),
          ]}
          onAccountMenuClick={function Ga() {}}
          renderNavLink={({ to, children }) => (
            <NavLink
              to={to}
              className={({ isActive }) =>
                classNames(
                  "flex items-center text-lg border-b-2 h-10",
                  isActive
                    ? "border-primaryDark text-primaryDark font-semibold"
                    : "border-transparent text-darkGray hover:text-primaryDark focus:text-primaryDark font-medium"
                )
              }
            >
              {children}
            </NavLink>
          )}
          gradientColors={[
            theme.colors?.primaryColor || "",
            theme.colors?.successColor || "",
          ]}
          socialIcons={[
            {
              icon: <FacebookOutlined className="text-32 text-white" />,
              url: "https://www.facebook.com/",
            },
            {
              icon: <TwitterOutlined className="text-32 text-white" />,
              url: "https://www.twitter.com/",
            },
            {
              icon: <InstagramOutlined className="text-32 text-white" />,
              url: "https://www.instagram.com/",
            },
            {
              icon: <GlobalOutlined className="text-32 text-white" />,
              url: "https://www.google.com/",
            },
          ]}
          logout={{
            label: t("Layout.logout"),
            handleLogout: () => handleLogout(),
          }}
          loggedIn={true}
          user={{
            firstName: "John",
            lastName: "Doe",
            mobileNumber: userName || "",
            email: "JohnDoe@email.com",
            points: walletData?.data.totalPoints || 0,
          }}
        >
          <></>
        </SharedHeader>
      </header>
      <div className="flex flex-col min-h-screen overflow-x-hidden">
        <div className="centered-container flex-1 my-8">
          <Outlet />
        </div>
      </div>
      <Footer
        mainLogo={
          <Link to={"/"}>
            <img
              className="w-48 max-h-20 object-contain ltr:object-left rtl:object-right"
              src={darkLogo}
              alt={"Company logo"}
            />
          </Link>
        }
        endSection={{
          content: (
            <div className="flex flex-wrap gap-2 md:justify-end">
              {t("Copyright")} {new Date().getFullYear()}
              <span className="mx-4 border-r border-black"></span>
              {t("PoweredBy")}
              <a href="#" className="flex items-center">
                <img
                  src={DsquaresLogo}
                  alt="Dsquares"
                  className="w-[7.375rem]"
                />
              </a>
            </div>
          ),
        }}
        links={
          <>
            {footerLinks.map((link) => (
              <Link key={link.href} to={link.href as To}>
                {link.children}
              </Link>
            ))}
          </>
        }
      />
    </>
  );
}
