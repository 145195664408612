import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLocales } from "src/store/slices/appLocales";

export function useAppTranslation(keyPrefix?: string) {
  const locales: Record<string, Record<string, string>> = useSelector(
    selectLocales
  );
  const { i18n, t } = useTranslation("", {
    keyPrefix: keyPrefix,
  });

  const translate = useCallback(
    (key: string) => {
      const keyWithPrefix = keyPrefix ? `${keyPrefix}.${key}` : key;
      return locales?.[i18n.language]?.[keyWithPrefix] || t(key);
    },
    [t, i18n, locales]
  );
  return {
    t: translate,
    i18n,
  };
}
