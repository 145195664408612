import { CaretDownOutlined } from "@ant-design/icons";
import { OTPPage } from "@dsquares/react-component-library";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyRegisterQuery, useLazyVerifyVoucherOtpQuery } from "src/store";
import bgAnimation from "src/assets/video/auth-bg-animation.mp4";
import { useToast } from "src/context/ToastContext";
import { LanguageService } from "src/services/Language";
import { useAppTranslation } from "src/hooks/useAppTranslation";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";

const OTP = () => {
  const { i18n, t } = useAppTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const notification = useToast();
  const appConfig = useSelector(selectAppConfig);

  const lightLogo = appConfig.lightLogo;
  const appVariant = appConfig.variant;

  const [callRegisterAPi] = useLazyRegisterQuery();
  const [verifyOtp] = useLazyVerifyVoucherOtpQuery();

  const handleVerify = (e: { code: string[] }) => {
    if (
      location.state &&
      "fullName" in location.state &&
      "mobileNumber" in location.state &&
      "password" in location.state &&
      "confirmPassword" in location.state
    ) {
      // came from signup page
      const { fullName, mobileNumber, password, confirmPassword } =
        location.state;
      registerApi(
        fullName,
        mobileNumber,
        password,
        confirmPassword,
        e.code.join("")
      );
    } else if (location.state && "mobileNumber" in location.state) {
      // came from forget password page
      const { mobileNumber } = location.state;
      verifyOtpApi(mobileNumber, e.code.join(""));
    }
  };

  const verifyOtpApi = async (mobileNumber: string, otp: string) => {
    try {
      var validMobileNumber = mobileNumber.replace("+2", "");

      const res = await verifyOtp({
        walletCode: validMobileNumber,
        otp: otp,
      });

      if (res?.isSuccess) {
        navigate("/reset-password", {
          state: { mobile: mobileNumber, otp: otp },
        });
      } else if (res?.isError) {
        if ("data" in res.error) {
          notification.open({
            type: "error",
            content: (res?.error?.data as any).errorMessage,
            duration: 3,
          });
        }
      }
    } catch (error: any) {
      notification.open({
        type: "error",
        content: error.message,
        duration: 3,
      });
    }
  };

  const registerApi = async (
    fullName: string,
    mobileNumber: string,
    password: string,
    confirmPassword: string,
    otp: string
  ) => {
    try {
      var validMobileNumber = mobileNumber.replace("+2", "");

      const res = await callRegisterAPi({
        UserName: validMobileNumber,
        FullName: fullName,
        Password: password,
        ConfirmPassword: confirmPassword,
        Otp: otp,
      });

      if (res?.isSuccess) {
        navigate("/login");
      } else if (res?.isError) {
        if ("data" in res.error) {
          notification.open({
            type: "error",
            content: (res?.error?.data as any).errorMessage,
            duration: 3,
          });
        }
      }
    } catch (error: any) {
      notification.open({
        type: "error",
        content: error.message,
        duration: 3,
      });
    }
  };

  return (
    <OTPPage
      variant={appVariant}
      background={
        <>
          <video
            autoPlay
            className="hidden md:block absolute inset-0 w-full h-full object-cover -z-10 "
            loop
            muted
          >
            <source src={bgAnimation} type="video/mp4" />
          </video>
          <div className="absolute hidden md:block inset-0 bg-primaryDark opacity-80 -z-10" />
        </>
      }
      header={{
        logo: (
          <a className="flex justify-center" href="https://dsquares.com">
            <img
              alt="Company logo"
              className="hidden md:block w-72"
              src={lightLogo}
            />
          </a>
        ),
      }}
      modalProps={{
        onSubmit: (e: any) => handleVerify(e),
      }}
      footer={{
        languageSwitcherProps: {
          items: [
            { label: "English", value: "en" },
            { label: "العربية", value: "ar" },
          ],
          handleDirection: (value: "ar" | "en") =>
            LanguageService.changeLanguage(value as "ar" | "en"),
          suffixLanguageIcon: <CaretDownOutlined className={"text-white"} />,
          textColor: "white",
          bordered: false,
        },
        logo: <p className="text-white">{t("Copyright")} Dsquares 2024</p>,
      }}
    >
      {/* This fragment is because children marked as required in this component */}
      {/* TODO: Fix in design system */}
      <></>
    </OTPPage>
  );
};

export default OTP;
