import { ForgetPassword as DemoForgetPasswordPage } from "@dsquares/react-component-library";
import { useNavigate } from "react-router-dom";
import { useLazySendOtpQuery } from "src/store";
import bgAnimation from "src/assets/video/auth-bg-animation.mp4";
import { useToast } from "src/context/ToastContext";
import { useSelector } from "react-redux";
import { selectAppConfig } from "src/store/slices/appConfig";

type ForgetPasswordFormValues = {
  mobile: string;
};

const ForgetPassword = () => {
  const navigate = useNavigate();
  const notification = useToast();
  const appConfig = useSelector(selectAppConfig);

  const lightLogo = appConfig.lightLogo;
  const appVariant = appConfig.variant;

  const [sendOtp] = useLazySendOtpQuery();

  const handleForgetPassword = async (e: ForgetPasswordFormValues) => {
    try {
      let validMobileNumber = e.mobile.replace("+2", "");

      const res = await sendOtp({
        walletCode: validMobileNumber,
        profileOtp: "3",
      });

      if (res.isSuccess) {
        if (res.data?.errorCode === "0") {
          navigate("/otp", {
            state: { mobileNumber: e.mobile },
          });
        }
      } else if (res.isError) {
        if ("data" in res.error) {
          notification.open({
            type: "error",
            content: (res?.error?.data as any).errorMessage,
            duration: 3,
          });
        }
      }
    } catch (error: any) {
      notification.open({
        type: "error",
        content: error.message,
        duration: 3,
      });
    }
  };

  return (
    <div>
      <DemoForgetPasswordPage
        background={
          <>
            <video
              autoPlay
              className="hidden md:block absolute inset-0 w-full h-full object-cover -z-10 "
              loop
              muted
            >
              <source src={bgAnimation} type="video/mp4" />
            </video>
            <div className="absolute hidden md:block inset-0 bg-primaryDark opacity-80 -z-10" />
          </>
        }
        header={{
          logo: (
            <a className="flex justify-center" href="https://dsquares.com">
              <img
                alt="Company logo"
                className="hidden md:block w-72"
                src={lightLogo}
              />
            </a>
          ),
        }}
        modal={{
          action: (e: ForgetPasswordFormValues) => handleForgetPassword(e),
        }}
      >
        {/* This fragment is because children marked as required in this component */}
        {/* TODO: Fix in design system */}
        <></>
      </DemoForgetPasswordPage>
    </div>
  );
};

export default ForgetPassword;
