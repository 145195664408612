import { useEffect, useState } from "react";

type DebounceOptions<T> = {
  delay?: number;
  callBack?: (debouncedValue: T) => void;
};
export function useDebounce<T>(
  value: T,
  { delay = 500, callBack }: DebounceOptions<T> = {}
) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      callBack?.(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, callBack]);

  return debouncedValue;
}
