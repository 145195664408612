import { useState } from "react";
import {
  useGetCategoriesListForOffersQuery,
  useGetDealsListPagedQuery,
  useGetMerchantListQuery,
} from "src/store";
import { Category } from "src/models/CategoriesModel";
import { Offers } from "src/models/OffersModel";
import { PARTNERS_ROUTE } from "src/models/RoutesModel";
import { useAppTranslation } from "src/hooks/useAppTranslation";

const useDeals = (
  pageNumber: number,
  pathname: string,
  sectionId: number | undefined
) => {
  const [categoryId, setCategoryId] = useState<number | undefined>(undefined);

  const { i18n } = useAppTranslation("OffersPage");

  // get categories
  const { data: categories } = useGetCategoriesListForOffersQuery({
    lang: i18n.language,
  });

  const transformedCategories: Category[] =
    categories?.map((category) => ({
      title: category?.Title,
      image: <img src={category?.Image} />,
      categoryId: category?.CategoryId,
    })) || [];

  // get offers, vouchers... etc
  const {
    data: offerData,
    error: offerError,
    isLoading: offersIsLoading,
    isFetching: offerIsFetching,
    isError: offersIsError,
  } = useGetDealsListPagedQuery(
    {
      evaluateTotalItemsCount: true,
      pageNumber: pageNumber,
      pageSize: 8, // Assuming pageSize is fixed, modify as needed
      categoryId: categoryId as any, // TODO: Fix package
      lang: i18n.language,
      sectionId: sectionId as any, // TODO: Fix package
    },
    {
      skip: pathname.toLowerCase() === PARTNERS_ROUTE,
      refetchOnMountOrArgChange: true,
    }
  );

  const transformedOffers: Offers[] =
    offerData?.Items.map((offer) => ({
      id: offer.Id,
      merchantTitle: offer.Title,
      image: <img src={offer.Image} />,
      description: offer.Description,
    })) || [];

  // get partners in partners route only
  const {
    data: merchantsData,
    error: merchantsError,
    isLoading: merchantsIsLoading,
    isFetching: merchantIsFetching,
    isError: merchantsIsError,
  } = useGetMerchantListQuery(
    {
      pageNumber: pageNumber,
      pageSize: 8, // Assuming pageSize is fixed, modify as needed
      categoryId: categoryId as any, // TODO: Fix package
      lang: i18n.language,
    },
    {
      skip: pathname.toLowerCase() !== PARTNERS_ROUTE,
      refetchOnMountOrArgChange: true,
    }
  );

  const transformedMerchants: Offers[] =
    merchantsData?.Data.map((offer) => ({
      id: offer.MerchantId,
      merchantTitle: offer.Title,
      image: <img src={offer.imageSrc} />,
      description: offer.Description,
    })) || [];

  // Category click
  const onCategoryClicked = (categoryId: number | undefined) => {
    setCategoryId(categoryId);
  };

  return {
    merchantsData,
    offerData,
    error: offerError ? offerError : merchantsError || null,
    categories: transformedCategories,
    transformedData:
      pathname.toLowerCase() === PARTNERS_ROUTE
        ? transformedMerchants
        : transformedOffers,
    totalItemsCount: offerData?.Paginator.TotalItems || merchantsData?.Count,
    isLoading:
      offersIsLoading ||
      offerIsFetching ||
      merchantIsFetching ||
      merchantsIsLoading,
    isError: offersIsError || merchantsIsError,
    onCategoryClicked,
    categoryId,
  };
};

export default useDeals;
