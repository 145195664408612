import {
  AmountInput,
  BranchCard,
  ItemDetails,
  QuantityInput,
  TransformInput,
} from "@dsquares/react-component-library";

import { Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OfferDetails } from "src/models/OffersModel";
import { capitalizeWord } from "src/utility/utility";
import DealsFlow from "src/components/RedemptionModal/DealsFlow";
import { Merchant } from "src/components/RedemptionModal/MerchantFlow";
import { useAppTranslation } from "src/hooks/useAppTranslation";

type DetailsPageProps = {
  parentPath: string;
};

const DetailsPage = ({ parentPath }: DetailsPageProps) => {
  const { i18n, t } = useAppTranslation("DetailsPage");

  let items = [];

  const [isDealsFlowModalOpen, setIsDealsFlowModalOpen] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();
  const offerData: OfferDetails = state?.data;

  const merchantData: Merchant = {
    id: offerData.MerchantId,
    name: offerData.Merchant,
    image: <img src={offerData.MerchantImage} alt={offerData.Merchant} />,
  };

  useEffect(() => {
    if (!offerData) navigate(parentPath);
  }, [navigate, offerData, parentPath]);

  const [amount, setAmount] = useState(0);

  const onChange = (value: any | null) => {
    setAmount(value || 0);
  };

  if (offerData?.Merchant) {
    items.push({
      id: 1,
      label: `${t("about")} ${offerData?.Merchant}`,
      children: offerData?.Description,
    });
  }
  if (offerData?.Terms) {
    items.push({
      id: offerData?.Id,
      label: t("terms"),
      children: <p>{offerData?.Terms}</p>,
    });
  }

  if (offerData?.Branches) {
    items.push({
      id: offerData?.Id,
      label: t("branches"),
      children: offerData?.Branches.map((branch) => (
        <BranchCard
          branch={{
            name: branch?.BranchName,
            address: branch?.BranchAddress,
            coordinates: {
              lat: String(branch?.Latitude),
              long: String(branch?.Longitude),
            },
          }}
        />
      )),
    });
  }

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={parentPath}>{capitalizeWord(parentPath.slice(1))}</Link>
            ),
          },
          { title: offerData?.MerchantName || offerData?.Merchant },
        ]}
      />

      <ItemDetails
        claimText={t("claim")}
        price={offerData?.Value && `${offerData?.Value} ${t("points")}`}
        categoryName={offerData?.CategoryName}
        percentage={offerData?.Discount && `${offerData?.Discount}%`}
        description={{
          content: (
            <p className="text-gray-400">
              {offerData?.Title || offerData?.Description}
            </p>
          ),
          maxRows: 10,
          seeMoreText: t("seeMore"),
        }}
        merchantLogo={
          <div className="bg-white min-h-40 flex justify-center rounded-xl shadow p-4">
            <img
              alt={`${offerData?.Merchant || offerData?.MerchantName}`}
              className="max-h-48 object-contain ltr:object-left rtl:object-right"
              src={offerData?.Image || offerData?.imageSrc}
            />
          </div>
        }
        onClaim={() => {
          setIsDealsFlowModalOpen(true);
        }}
        sidebarItems={items}
        title={offerData?.Merchant || offerData?.MerchantName}
      >
        {parentPath !== "/partners" && (
          <InputBySection
            pathname={parentPath}
            amount={amount}
            onChange={onChange}
          />
        )}
      </ItemDetails>

      <DealsFlow
        isOpen={isDealsFlowModalOpen}
        onModalClose={() => {
          setIsDealsFlowModalOpen(false);
        }}
        merchant={merchantData || ({} as any)}
      />
    </>
  );
};

export default DetailsPage;

type InputBySectionProps = {
  pathname: string;
  amount: number;
  onChange: (value: any | null) => void;
};

const InputBySection = ({
  pathname,
  amount,
  onChange,
}: InputBySectionProps) => {
  const { t } = useAppTranslation();
  switch (pathname) {
    case "/offers":
      return (
        <AmountInput
          title={t("DetailsPage.amount")}
          onChangeAmount={onChange}
          amount={amount}
        />
      );

    case "/vouchers":
      return (
        <QuantityInput
          onChangeTotal={onChange}
          // TODO: fix from api
          options={[
            { label: "100", value: 100 },
            { label: "200", value: 200 },
            { label: "300", value: 300 },
          ]}
        />
      );

    case "/giftcards":
      return (
        <QuantityInput
          onChangeTotal={onChange}
          // TODO: fix from api
          options={[
            { label: "100", value: 100 },
            { label: "200", value: 200 },
            { label: "300", value: 300 },
          ]}
        />
      );

    case "/programs":
      return (
        <div className="contents [&_svg]:rtl:rotate-180">
          <TransformInput
            accessInputProps={{
              label: t("DetailsPage.memberId"),
              placeholder: t("DetailsPage.memberPlaceholder"),
            }}
            transformMessage={`1 ${t("DetailsPage.points")} = 0.01 ${t(
              "RedemptionFlow.Currency"
            )}`}
            pointsInputProps={{
              label: t("DetailsPage.point"),
              placeholder: "Enter Amount",
            }}
            transformedPointsInputProps={{
              label: t("DetailsPage.allPoints"),
            }}
            onChangeAmount={onChange}
            factor={0.01}
          />
        </div>
      );

    default:
      return null;
  }
};
